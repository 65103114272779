<template>
  <div v-if="!loading" class="mt-4">
    <!--
    <div class="bg-lightgray p-4 mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Sector</label>
        <input
          v-model.trim="search"
          type="text"
          class="
            relative
            py-3
            px-2
            w-full
            bg-white
            rounded
            shadow
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none focus:shadow-outline
          "
          placeholder="Buscar sector"
        />
      </div>
    </div>
    -->
    <p class="text-sm mb-4">Listado de sectores</p>
    <div>
      <div class="pt-2 cursor-pointer">
        <div class="flex pb-2">
          <div class="flex items-center justify-center">
            <input
              v-model="isSelectAll"
              type="checkbox"
              class="
                checked:bg-primary checked:border-transparent
                cursor-pointer
                ...
              "
              @click="onSelectAll($event)"
            />
            <span class="select-none text-xs ml-2">
              {{ isSelectAll ? "Quitar selección a" : "Seleccionar" }} todos
            </span>
          </div>
        </div>
      </div>
      <template v-for="(sector, i) in sectors" :key="i">
        <div class="pt-2 cursor-pointer">
          <div class="flex pb-2">
            <div class="flex items-center justify-center">
              <input
                v-model="sector.selected"
                type="checkbox"
                class="
                  checked:bg-primary checked:border-transparent
                  cursor-pointer
                  ...
                "
                @click="onSelectSector($event, sector)"
              />
              <span class="select-none text-xs ml-2">
                {{ sector.name }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-if="sectors && sectors.length" class="mt-5">
      <button
        class="
          rounded
          bg-primary
          py-2
          px-4
          text-white
          w-full
          flex
          justify-center
        "
        :disabled="loadingSave"
        @click="onSaveGroupSectors"
      >
        <LoadingButtonIcon v-if="loadingSave" />
        Guardar
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["onComplete"]);

const props = defineProps({
  groupKey: {
    type: String,
    required: true,
  },
  sectorsSelected: {
    type: Array,
    default: () => [],
  },
});
const store = useStore();
const sectors = ref([]);
const isSelectAll = ref(false);
const loading = ref(true);
const loadingSave = ref(false);

const getSectors = computed(() => store.getters.getSectors);

const init = async () => {
  if (!getSectors.value) await store.dispatch("getAllSectors");

  let groupSectors = await store.dispatch("getAllGroupSectors", {
    groupKey: props.groupKey,
  });

  let selected = false;
  (getSectors.value || []).forEach((s) => {
    selected = false;

    if (groupSectors.find((gd) => gd.sector.key === s.key)) selected = true;

    sectors.value.push({
      ...s,
      selected,
    });
  });

  if (sectors.value.length === groupSectors.length) isSelectAll.value = true;

  loading.value = false;
};

init();

const onSelectAll = (event) => {
  let checked = event.target.checked;
  sectors.value.forEach((s) => (s.selected = checked));
};

const onSelectSector = (event, sector) => {
  const sectorInd = sectors.value.findIndex((s) => s.key === sector.key);

  if (sectorInd >= 0) {
    let checked = event.target.checked;
    // if (!checked) {
    //   const subdeparturesSelected = sectors.value[
    //     sectorInd
    //   ].subdepartures.filter((sd) => sd.selected).length;
    //   if (subdeparturesSelected - 1 > 0)
    //     checked = sectors.value[sectorInd].selected;
    // }

    sectors.value[sectorInd].selected = checked;
  }
};

const onSaveGroupSectors = async () => {
  try {
    loadingSave.value = true;

    let sectorsKey = [];

    for (let sector of sectors.value) {
      if (!sector.selected) continue;

      sectorsKey.push(sector.key);
    }

    await store.dispatch("saveGroupSectors", {
      groupKey: props.groupKey,
      sectorsKey: sectorsKey,
    });

    emit("onComplete");
  } catch (error) {
    console.error(error);
  } finally {
    loadingSave.value = false;
  }
};
</script>
