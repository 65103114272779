<template>
  <div class="text-left bg-lightgray py-4 flex justify-between lg:px-10 px-5">
    <h2 class="text-secondary text-2xl font-medium my-auto">Grupos</h2>
    <button
      class="
        rounded
        border border-primary
        bg-white
        py-2
        px-4
        text-primary
        font-medium
        flex
      "
      @click="onOpenDrawerGroup({ action: 'create' })"
    >
      Crear grupo de tareo
    </button>
  </div>
  <div>
    <div v-if="getGroups" class="w-full">
      <div v-for="(group, i) in getGroups" :key="i">
        <!-- class="whitespace-nowrap" -->
        <div
          class="
            grid grid-cols-4
            text-left text-secondary
            font-light
            px-6
            py-4
            bg-lightgray
          "
        >
          <div class="flex items-center">
            <div
              class="
                w-8
                h-8
                bg-white
                p-2
                flex
                items-center
                justify-center
                cursor-pointer
              "
              @click="groupsVisibility[i] = !groupsVisibility[i]"
            >
              <svg
                v-if="groupsVisibility[i]"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.59 8L6 3.05121L1.41 8L1.33844e-07 6.469L6 -5.24537e-07L12 6.469L10.59 8Z"
                  fill="#3D3D67"
                />
              </svg>
              <svg
                v-else
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.41 0L6 4.94879L10.59 0L12 1.531L6 8L0 1.531L1.41 0Z"
                  fill="#3D3D67"
                />
              </svg>
            </div>
            <div class="text-sm text-secondary font-bold mt-1 ml-2">
              {{ group.name }}
            </div>
          </div>

          <div class="text-sm mt-1">
            <span>Cantidad de obreros</span>
            <span class="font-bold text-primary ml-2">{{
              group.members?.length || 0
            }}</span>
          </div>

          <div class="col-span-2">
            <a
              class="text-primary font-bold cursor-pointer xl:mr-10 text-sm"
              @click="
                onOpenDrawerGroupMembers({
                  action: 'update',
                  group: group,
                })
              "
            >
              Agregar trabajador
            </a>
            <a
              class="text-primary font-bold cursor-pointer xl:mr-10 text-sm"
              @click="
                onOpenDrawerGroupDepartures({
                  group: group,
                })
              "
            >
              Agregar partidas
            </a>
            <a
              class="text-primary font-bold cursor-pointer xl:mr-10 text-sm"
              @click="
                onOpenDrawerGroupSectors({
                  group: group,
                })
              "
            >
              Agregar sectores
            </a>
            <a
              class="text-primary font-bold cursor-pointer text-sm ml-5sm:ml-10"
              @click="
                onOpenDrawerGroup({
                  action: 'update',
                  group: group,
                })
              "
            >
              Editar
            </a>
          </div>
        </div>
        <div v-if="groupsVisibility[i]" class="px-16 py-4">
          <div class="grid grid-cols-4 border-b pb-2">
            <div>
              <div class="flex items-center">
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  class="w-4 flex-2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.2959 11.7297H0.73673C0.315742 11.7297 0 11.414 0 10.993V10.7123C0 10.2913 0.315742 9.97559 0.73673 9.97559H15.2608C15.6818 9.97559 15.9976 10.2913 15.9976 10.7123V10.993C16.0327 11.414 15.6818 11.7297 15.2959 11.7297Z"
                    fill="#8A00E0"
                  />
                  <path
                    d="M1.08828 8.8878C1.08828 8.8878 0.772534 3.87101 4.6316 1.80115L5.05259 5.80055C5.05259 5.80055 5.05259 6.64252 5.75423 6.57236C6.45588 6.53728 6.21031 5.59005 6.21031 5.59005L5.75423 1.38016C5.75423 1.38016 5.47358 0.292609 6.45588 0.292609C7.43819 0.292609 9.57822 0.292609 9.57822 0.292609C9.57822 0.292609 10.4904 0.257527 10.1746 1.34508C10.0343 2.81854 9.68346 5.80055 9.68346 5.80055C9.68346 5.80055 9.54313 6.64252 10.1395 6.64252C10.7359 6.64252 10.8412 6.18645 10.8412 5.97596C10.8412 5.73038 11.2973 1.83623 11.2973 1.83623C11.2973 1.83623 13.297 3.06412 14.174 5.16906C15.0511 7.27401 14.7704 8.85272 14.7704 8.85272L1.08828 8.8878Z"
                    fill="#8A00E0"
                  />
                </svg>
                <div class="ml-2 text-secondary font-medium flex-1 text-sm">
                  {{
                    group.foreman
                      ? `${group.foreman.lastName} ${group.foreman.lastName2} ${group.foreman.name}`
                      : ""
                  }}
                </div>
              </div>
            </div>
            <div class="text-secondary font-medium text-sm">Tareador</div>
          </div>
          <template
            v-for="(member, memberInd) in group.members"
            :key="memberInd"
          >
            <div class="grid grid-cols-5 mt-5">
              <div class="flex items-center col-span-2">
                <span class="w-8 text-xs text-secondary font-bold"
                  >{{ memberInd + 1 }}.</span
                >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  class="w-4 flex-2"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.1889 12.4936C13.8521 11.6957 13.3632 10.9709 12.7497 10.3596C12.1379 9.74661 11.4133 9.25785 10.6157 8.92034C10.6086 8.91677 10.6014 8.91499 10.5943 8.91141C11.7068 8.10784 12.43 6.79891 12.43 5.32213C12.43 2.8757 10.4479 0.893555 8.00144 0.893555C5.55501 0.893555 3.57287 2.8757 3.57287 5.32213C3.57287 6.79891 4.29608 8.10784 5.40858 8.9132C5.40144 8.91677 5.3943 8.91856 5.38715 8.92213C4.58715 9.25963 3.8693 9.74356 3.25323 10.3614C2.64021 10.9731 2.15145 11.6978 1.81394 12.4953C1.48237 13.2762 1.30355 14.1133 1.28715 14.9614C1.28668 14.9805 1.29002 14.9994 1.29699 15.0172C1.30395 15.0349 1.3144 15.0511 1.32771 15.0647C1.34103 15.0784 1.35694 15.0892 1.3745 15.0966C1.39207 15.104 1.41095 15.1079 1.43001 15.1078H2.50144C2.58001 15.1078 2.64251 15.0453 2.6443 14.9686C2.68001 13.59 3.23358 12.2989 4.21215 11.3203C5.22465 10.3078 6.5693 9.7507 8.00144 9.7507C9.43358 9.7507 10.7782 10.3078 11.7907 11.3203C12.7693 12.2989 13.3229 13.59 13.3586 14.9686C13.3604 15.0471 13.4229 15.1078 13.5014 15.1078H14.5729C14.5919 15.1079 14.6108 15.104 14.6284 15.0966C14.6459 15.0892 14.6619 15.0784 14.6752 15.0647C14.6885 15.0511 14.6989 15.0349 14.7059 15.0172C14.7129 14.9994 14.7162 14.9805 14.7157 14.9614C14.6979 14.1078 14.5211 13.2775 14.1889 12.4936ZM8.00144 8.39356C7.1818 8.39356 6.41037 8.07391 5.83001 7.49356C5.24965 6.9132 4.93001 6.14177 4.93001 5.32213C4.93001 4.50248 5.24965 3.73106 5.83001 3.1507C6.41037 2.57034 7.1818 2.2507 8.00144 2.2507C8.82108 2.2507 9.59251 2.57034 10.1729 3.1507C10.7532 3.73106 11.0729 4.50248 11.0729 5.32213C11.0729 6.14177 10.7532 6.9132 10.1729 7.49356C9.59251 8.07391 8.82108 8.39356 8.00144 8.39356Z"
                    fill="#8A00E0"
                  />
                </svg>

                <div class="ml-2 text-sm flex-1">
                  {{ member.user.lastName }} {{ member.user.lastName2 }}
                  {{ member.user.name }}
                </div>
              </div>

              <div class="text-sm">
                {{ member.user.speciality?.name }}
              </div>

              <div class="text-sm">
                {{ member.user.category?.name }}
              </div>

              <div>
                <LoadingButtonIcon
                  v-if="
                    loadingDeleteMember &&
                    memberSelected.key === member.user.key
                  "
                  color="#E00F57"
                />
                <button
                  v-else
                  @click="
                    onDeleteMember({
                      groupKey: group.key,
                      member: member,
                    })
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M5.28544 2.14118H5.14258C5.22115 2.14118 5.28544 2.0769 5.28544 1.99833V2.14118H10.714V1.99833C10.714 2.0769 10.7783 2.14118 10.8569 2.14118H10.714V3.4269H11.9997V1.99833C11.9997 1.36797 11.4872 0.855469 10.8569 0.855469H5.14258C4.51222 0.855469 3.99972 1.36797 3.99972 1.99833V3.4269H5.28544V2.14118ZM14.2854 3.4269H1.71401C1.39794 3.4269 1.14258 3.68225 1.14258 3.99833V4.56976C1.14258 4.64833 1.20686 4.71261 1.28544 4.71261H2.36401L2.80508 14.0519C2.83365 14.6608 3.33722 15.1412 3.94615 15.1412H12.0533C12.664 15.1412 13.1658 14.6626 13.1944 14.0519L13.6354 4.71261H14.714C14.7926 4.71261 14.8569 4.64833 14.8569 4.56976V3.99833C14.8569 3.68225 14.6015 3.4269 14.2854 3.4269ZM11.9158 13.8555H4.08365L3.65151 4.71261H12.3479L11.9158 13.8555Z"
                      fill="#E00F57"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="getGroups.length === 0">Aún no hay grupos registrados</div>
    </div>
    <div v-else class="flex justify-center">
      <img :src="require('@/assets/loader.gif')" width="250" />
    </div>
  </div>
  <EndDrawer :open="drawerGroup" @update:open="drawerGroup = $event">
    <GroupForm
      v-if="drawerGroup"
      :action="groupFormAction"
      :group-selected="groupSelected"
      @onComplete="drawerGroup = false"
    />
  </EndDrawer>
  <EndDrawer
    :open="drawerGroupMembers"
    @update:open="drawerGroupMembers = $event"
  >
    <GroupMembersForm
      v-if="drawerGroupMembers"
      :group-selected="groupSelected"
    />
  </EndDrawer>
  <EndDrawer
    :open="drawerGroupDepartures"
    @update:open="drawerGroupDepartures = $event"
  >
    <GroupDepartures
      v-if="drawerGroupDepartures"
      :group-key="groupSelected.key"
      @onComplete="drawerGroupDepartures = false"
    />
  </EndDrawer>
  <EndDrawer
    :open="drawerGroupSectors"
    @update:open="drawerGroupSectors = $event"
  >
    <GroupSectors
      v-if="drawerGroupSectors"
      :group-key="groupSelected.key"
      @onComplete="drawerGroupSectors = false"
    />
  </EndDrawer>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import GroupForm from "./forms/Group";
import GroupDepartures from "./forms/GroupDepartures";
import GroupSectors from "./forms/GroupSectors";
import GroupMembersForm from "./forms/GroupMembers";

const store = useStore();

const drawerGroup = ref(false);
const drawerGroupMembers = ref(false);
const drawerGroupDepartures = ref(false);
const drawerGroupSectors = ref(false);
const groupFormAction = ref("create");
const groupsVisibility = ref([]);
const groupSelected = ref(null);
const memberSelected = ref(null);
const loadingDeleteMember = ref(false);

const getWork = computed(() => store.getters.getWork);
const getGroups = computed(() => store.getters.getGroups);
// const getForemans = computed(() => store.getters.getForemans);
const getMembers = computed(() => store.getters.getMembers);
const getSpecialities = computed(() => store.getters.getSpecialities);
// const getSectors = computed(() => store.getters.getSectors);

watch(
  () => getGroups.value,
  (currentValue) => {
    if (currentValue) {
      for (let i = 0; i < currentValue.length; i++) {
        groupsVisibility.value[i] = false;
      }
    } else {
      groupsVisibility.value = [];
    }
  }
);

const init = async () => {
  if (!getSpecialities.value)
    await store.dispatch("getAllSpecialities", { type: "User" });

  if (!getMembers.value) await store.dispatch("getAllMembers");

  await store.dispatch("getAllGroups", { workKey: getWork.value.key });
};

init();

const onOpenDrawerGroup = ({ action, group = null }) => {
  groupSelected.value = { ...group };
  drawerGroup.value = true;
  groupFormAction.value = action;
};

const onOpenDrawerGroupMembers = ({ group }) => {
  groupSelected.value = { ...group };
  drawerGroupMembers.value = true;
};

const onOpenDrawerGroupDepartures = ({ group }) => {
  groupSelected.value = { ...group };
  drawerGroupDepartures.value = true;
};

const onOpenDrawerGroupSectors = ({ group }) => {
  groupSelected.value = { ...group };
  drawerGroupSectors.value = true;
};

const onDeleteMember = async ({ groupKey, member }) => {
  try {
    loadingDeleteMember.value = true;
    memberSelected.value = { ...member };

    await store.dispatch("deleteGroupMember", {
      groupKey: groupKey,
      key: memberSelected.value.key,
    });

    memberSelected.value = null;
  } catch (error) {
    console.error(error);
  } finally {
    loadingDeleteMember.value = false;
  }
};
</script>
