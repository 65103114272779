<template>
  <div class="bg-lightgray p-4">
    <!-- <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Especialidad</label>
        <select
          v-model="specialityKey"
          class="
            relative
            py-3
            px-2
            w-full
            bg-white
            rounded
            shadow
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none focus:shadow-outline
          "
          placeholder="Seleccione"
        >
          <option value="">Todas</option>
          <option
            v-for="speciality in getSpecialities"
            :key="speciality"
            :value="speciality.key"
          >
            {{ speciality.name }}
          </option>
        </select>
      </div>
    </div> -->
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Obrero</label>
        <input
          v-model.trim="memberSearchText"
          type="text"
          class="
            relative
            py-3
            px-2
            w-full
            bg-white
            rounded
            shadow
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none focus:shadow-outline
          "
          placeholder="Buscar obrero"
        />
      </div>
    </div>
  </div>
  <div v-show="loading" class="flex justify-center">
    <img :src="require('@/assets/loader.gif')" width="100" />
  </div>
  <div v-if="matchMembers && !loading" class="mt-4">
    <p class="text-sm">Listado de obreros</p>

    <template v-for="(member, memberInd) in matchMembers" :key="memberInd">
      <div
        class="flex pt-2 cursor-pointer"
        @click="onAddMember({ memberKey: member.key })"
      >
        <div>
          <div class="flex ml-1 pb-2">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              class="mt-1 w-4"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.1889 12.4936C13.8521 11.6957 13.3632 10.9709 12.7497 10.3596C12.1379 9.74661 11.4133 9.25785 10.6157 8.92034C10.6086 8.91677 10.6014 8.91499 10.5943 8.91141C11.7068 8.10784 12.43 6.79891 12.43 5.32213C12.43 2.8757 10.4479 0.893555 8.00144 0.893555C5.55501 0.893555 3.57287 2.8757 3.57287 5.32213C3.57287 6.79891 4.29608 8.10784 5.40858 8.9132C5.40144 8.91677 5.3943 8.91856 5.38715 8.92213C4.58715 9.25963 3.8693 9.74356 3.25323 10.3614C2.64021 10.9731 2.15145 11.6978 1.81394 12.4953C1.48237 13.2762 1.30355 14.1133 1.28715 14.9614C1.28668 14.9805 1.29002 14.9994 1.29699 15.0172C1.30395 15.0349 1.3144 15.0511 1.32771 15.0647C1.34103 15.0784 1.35694 15.0892 1.3745 15.0966C1.39207 15.104 1.41095 15.1079 1.43001 15.1078H2.50144C2.58001 15.1078 2.64251 15.0453 2.6443 14.9686C2.68001 13.59 3.23358 12.2989 4.21215 11.3203C5.22465 10.3078 6.5693 9.7507 8.00144 9.7507C9.43358 9.7507 10.7782 10.3078 11.7907 11.3203C12.7693 12.2989 13.3229 13.59 13.3586 14.9686C13.3604 15.0471 13.4229 15.1078 13.5014 15.1078H14.5729C14.5919 15.1079 14.6108 15.104 14.6284 15.0966C14.6459 15.0892 14.6619 15.0784 14.6752 15.0647C14.6885 15.0511 14.6989 15.0349 14.7059 15.0172C14.7129 14.9994 14.7162 14.9805 14.7157 14.9614C14.6979 14.1078 14.5211 13.2775 14.1889 12.4936ZM8.00144 8.39356C7.1818 8.39356 6.41037 8.07391 5.83001 7.49356C5.24965 6.9132 4.93001 6.14177 4.93001 5.32213C4.93001 4.50248 5.24965 3.73106 5.83001 3.1507C6.41037 2.57034 7.1818 2.2507 8.00144 2.2507C8.82108 2.2507 9.59251 2.57034 10.1729 3.1507C10.7532 3.73106 11.0729 4.50248 11.0729 5.32213C11.0729 6.14177 10.7532 6.9132 10.1729 7.49356C9.59251 8.07391 8.82108 8.39356 8.00144 8.39356Z"
                :fill="member.userEntity === 'User' ? '#021f59' : '#8A00E0'"
              />
            </svg>
            <div class="ml-2">
              <div class="font-medium text-gray-500 text-sm pb-1">
                {{ member.lastName }} {{ member.lastName2 }} {{ member.name }}
              </div>
              <div class="text-gray-500 text-xs">DNI {{ member.dni }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-if="matchMembers.length" class="mt-5">
      <button
        class="
          rounded
          bg-primary
          py-2
          px-4
          text-white
          w-full
          flex
          justify-center
        "
        :disabled="loadingAddAll"
        @click="onAddAllMembers"
      >
        <LoadingButtonIcon v-if="loadingAddAll" />
        Agregar todos
      </button>
    </div>
    <div v-else>
      <h6 class="text-gray-400 text-center caption pt-4">
        No se encontraron obreros
      </h6>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, watch, computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  groupSelected: {
    type: Object,
    default: () => {},
  },
});

const store = useStore();

const specialityKey = ref("");
const memberSearchText = ref("");
const timerToSearch = ref(null);
const matchMembers = ref([]);
const loading = ref(false);
const loadingAddAll = ref(false);
const loadingAddOne = ref(false);

const getMembers = computed(() => store.getters.getMembers);

watch(
  () => specialityKey.value,
  () => onSearchMember()
);

watch(
  () => memberSearchText.value,
  () => onSearchMember()
);

const init = () => {
  loading.value = true;

  matchMembers.value = getMembers.value.filter(
    (m) =>
      m.key !== props.groupSelected.foreman.key &&
      !props.groupSelected.members.find((gm) => gm.user.key === m.key)
  );

  loading.value = false;
};

init();

const onSearchMember = () => {
  clearTimeout(timerToSearch.value);

  timerToSearch.value = setTimeout(async () => {
    loading.value = true;

    let members = await store.dispatch("searchAllMembers", {
      qSearch: memberSearchText.value,
      specialityKey: specialityKey.value,
    });

    matchMembers.value = members.filter(
      (m) =>
        m.key !== props.groupSelected.foreman.key &&
        !props.groupSelected.members.find((gm) => gm.user.key === m.key)
    );

    loading.value = false;
  }, 200);
};

const addGroupMember = async ({ memberKey }) => {
  try {
    loadingAddOne.value = true;

    await store.dispatch("createGroupMember", {
      groupKey: props.groupSelected.key,
      userKey: memberKey,
    });

    const memberInd = matchMembers.value.findIndex((m) => m.key === memberKey);
    if (memberInd >= 0) matchMembers.value.splice(memberInd, 1);
  } catch (error) {
    console.error(error);
  } finally {
    loadingAddOne.value = false;
  }
};

const onAddMember = async ({ memberKey }) => {
  if (!loadingAddOne.value && !loadingAddAll.value)
    await addGroupMember({ memberKey });
};

const onAddAllMembers = async () => {
  try {
    loadingAddAll.value = true;

    const members = matchMembers.value;

    const promises = [];

    members.forEach((member) => {
      promises.push(addGroupMember({ memberKey: member.key }));
    });

    await Promise.all(promises);
  } catch (error) {
    console.error(error);
  } finally {
    loadingAddAll.value = false;
  }
};
</script>
