<template>
  <div v-if="!loading" class="mt-4">
    <div class="bg-lightgray p-4 mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Partida</label>
        <input
          v-model.trim="search"
          type="text"
          class="
            relative
            py-3
            px-2
            w-full
            bg-white
            rounded
            shadow
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none focus:shadow-outline
          "
          placeholder="Buscar partida"
        />
      </div>
    </div>
    <p class="text-sm mb-4">Listado de partidas</p>
    <div v-if="search">
      <template v-for="(matchDeparture, i) in matchDepartures" :key="`d-${i}`">
        <div class="flex pt-2 cursor-pointer">
          <div class="flex pb-2">
            <div class="flex items-center justify-center">
              <input
                v-model="matchDeparture.selected"
                type="checkbox"
                class="
                  checked:bg-primary checked:border-transparent
                  cursor-pointer
                  ...
                "
                @click="onSelectDeparture($event, matchDeparture)"
              />
              <span class="select-none text-xs ml-2">
                {{ matchDeparture.name }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-else>
      <template v-for="(departure, i) in departures" :key="i">
        <div class="pt-2 cursor-pointer">
          <div class="flex pb-2">
            <div class="flex items-center justify-center">
              <input
                v-model="departure.selected"
                type="checkbox"
                class="
                  checked:bg-primary checked:border-transparent
                  cursor-pointer
                  ...
                "
                @click="onSelectDeparture($event, departure)"
              />
              <span class="select-none text-xs ml-2">
                {{ departure.name }}
              </span>
            </div>
          </div>
          <div v-if="departure.subdepartures.length">
            <template
              v-for="(subdeparture, i) in departure.subdepartures"
              :key="i"
            >
              <div class="flex items-center pl-5 pb-3">
                <input
                  v-model="subdeparture.selected"
                  type="checkbox"
                  class="
                    checked:bg-primary checked:border-transparent
                    cursor-pointer
                    ...
                  "
                  @click="onSelectDeparture($event, departure, subdeparture)"
                />
                <span class="select-none text-xs ml-2">
                  {{ subdeparture.name }}
                </span>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div v-if="departures && departures.length" class="mt-5">
      <button
        class="
          rounded
          bg-primary
          py-2
          px-4
          text-white
          w-full
          flex
          justify-center
        "
        :disabled="loadingSave"
        @click="onSaveGroupDepartures"
      >
        <LoadingButtonIcon v-if="loadingSave" />
        Guardar
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, watch } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["onComplete"]);

const props = defineProps({
  groupKey: {
    type: String,
    required: true,
  },
  departuresSelected: {
    type: Array,
    default: () => [],
  },
});
const store = useStore();

const search = ref("");
const timerToSearch = ref(null);
const matchDepartures = ref([]);
const departures = ref([]);
const loading = ref(true);
const loadingSave = ref(false);

const getDepartures = computed(() => store.getters.getDepartures);

watch(
  () => search.value,
  () => searchDepartures()
);

const init = async () => {
  if (!getDepartures.value) await store.dispatch("getAllDepartures");

  let groupDepartures = await store.dispatch("getAllGroupDepartures", {
    groupKey: props.groupKey,
  });

  let selected = false;
  let subdepartures = [];
  (getDepartures.value || []).forEach((d) => {
    selected = false;
    subdepartures = [];

    if (groupDepartures.find((gd) => gd.departure.key === d.key))
      selected = true;

    if (d.subdepartures?.length) {
      subdepartures = d.subdepartures.map((sd) => ({
        ...sd,
        selected: !!groupDepartures.find((gd) => gd.departure.key === sd.key),
      }));

      if (subdepartures.filter((sd) => sd.selected).length) selected = true;
    }

    departures.value.push({
      ...d,
      selected,
      subdepartures: [...subdepartures],
    });
  });

  loading.value = false;
};

init();

const searchDepartures = async () => {
  clearTimeout(timerToSearch.value);

  timerToSearch.value = setTimeout(async () => {
    let selectedDeparturesKey = departures.value
      .filter((d) => d.selected)
      .map((d) => d.key);

    let matches = await store.dispatch("searchAllDepartures", {
      qSearch: search.value,
    });
    matches.forEach((m) => {
      if (selectedDeparturesKey.includes(m.key)) m.selected = true;
    });

    matchDepartures.value = matches;
  }, 400);
};

const onSelectDeparture = (event, departure, subdeparture) => {
  const departureInd = departures.value.findIndex(
    (d) => d.key === departure.key
  );

  if (departureInd >= 0) {
    let checked = event.target.checked;
    if (!checked) {
      const subdeparturesSelected = departures.value[
        departureInd
      ].subdepartures.filter((sd) => sd.selected).length;
      if (subdeparturesSelected - 1 > 0)
        checked = departures.value[departureInd].selected;
    }

    departures.value[departureInd].selected = checked;

    let hasSubdepartures = departures.value[departureInd].subdepartures?.length;

    if (subdeparture && hasSubdepartures) {
      const subdepartureInd = departures.value[
        departureInd
      ].subdepartures.findIndex((sd) => sd.key === subdeparture.key);
      if (subdepartureInd >= 0)
        departures.value[departureInd].subdepartures[subdepartureInd].selected =
          event.target.checked;
    }

    if (!subdeparture && hasSubdepartures) {
      for (let sd of departures.value[departureInd].subdepartures)
        sd.selected = event.target.checked;
    }
  }
};

const onSaveGroupDepartures = async () => {
  try {
    loadingSave.value = true;

    let departuresKey = [];

    for (let departure of departures.value) {
      if (!departure.selected) continue;

      if (departure.subdepartures?.length) {
        departure.subdepartures.forEach((subdeparture) => {
          if (subdeparture.selected) departuresKey.push(subdeparture.key);
        });
      } else {
        departuresKey.push(departure.key);
      }
    }

    // let departuresKey = departures.value
    //   .filter((d) => d.selected)
    //   .map((d) => d.key);

    await store.dispatch("saveGroupDepartures", {
      groupKey: props.groupKey,
      departuresKey: departuresKey,
    });

    emit("onComplete");
  } catch (error) {
    console.error(error);
  } finally {
    loadingSave.value = false;
  }
};
</script>
