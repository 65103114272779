<template>
  <Form
    @submit="onSave"
    :validation-schema="{
      userKey: 'required',
      name: '',
    }"
  >
    <div>
      <h4 class="mb-4 text-secondary">
        {{ action === "create" ? "Crear" : "Editar" }} grupo
      </h4>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Nombre (opcional)</label>
        <Field v-model="name" name="name" v-slot="{ field }">
          <input
            v-bind="field"
            type="text"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            placeholder="Ingrese nombre"
          />
        </Field>
      </div>
      <div>
        <ErrorMessage name="name" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label>Tareador</label>
        <Field v-model="userKey" name="userKey" v-slot="{ field }">
          <input v-bind="field" list="foremans" type="hidden" />
          <input
            v-model="foreman"
            placeholder="Buscar tareador"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            @keyup="onSearchForeman"
          />
          <!-- <datalist id="foremans">
            <option
              v-for="foreman in getForemans"
              :key="foreman.key"
              :data-value="foreman.key"
              :value="`${foreman.name} ${foreman.lastName}`"
              :selected="userKey === foreman.key"
            >
              DNI: {{ foreman.dni }}
            </option>
          </datalist> -->
          <template v-if="showSearchResult">
            <ul v-if="getForemans && getForemans.length" class="w-full">
              <li
                v-for="foreman in getForemans"
                :key="foreman.key"
                class="pt-3 cursor-pointer"
                @click="
                  onSelectForeman(
                    foreman.key,
                    `${foreman.lastName} ${foreman.lastName2} ${foreman.name} `
                  )
                "
              >
                {{ foreman.lastName }} {{ foreman.lastName2 }}
                {{ foreman.name }}
              </li>
            </ul>
            <div
              v-if="getForemans && getForemans.length === 0 && foreman"
              class="w-full py-2 text-sm font-thin"
            >
              No se encontraron resultados
            </div>
          </template>
          <!-- <select
            v-bind="field"
            class="
              relative
              py-3
              px-2
              w-full
              bg-white
              rounded
              shadow
              outline-none
              text-gray-700
              placeholder-gray-400
              focus:outline-none focus:shadow-outline
            "
            placeholder="Seleccione"
          >
            <option
              v-for="member in getMembers"
              :key="member.key"
              :value="member.key"
              :selected="userKey === member.key"
            >
              {{ member.name }} {{ member.lastName }}
            </option>
          </select> -->
        </Field>
      </div>
      <div>
        <ErrorMessage name="userKey" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>

    <Alert
      v-if="responseMessage"
      :type="responseType"
      :text="responseMessage"
    ></Alert>
    <div>
      <button
        class="
          rounded
          bg-primary
          py-2
          px-4
          text-white
          w-full
          flex
          justify-center
        "
        type="submit"
        :disabled="loadingSave"
      >
        <LoadingButtonIcon v-if="loadingSave" />
        Guardar
      </button>
      <button
        v-if="action === 'update'"
        class="rounded bg-red-600 py-2 px-4 text-white w-full mt-4"
        :disabled="loadingDelete"
        @click.prevent="onDelete"
      >
        <LoadingButtonIcon v-if="loadingDelete" />
        Eliminar grupo
      </button>
    </div>
  </Form>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from "vue";
import { useStore } from "vuex";
import { useField } from "vee-validate";

const props = defineProps({
  action: {
    type: String,
    default: "create",
  },
  groupSelected: {
    type: Object,
    default: () => {},
  },
});
const emits = defineEmits(["onComplete"]);
const store = useStore();

const { value: userKey } = useField("userKey");
const { value: name } = useField("name");

const foreman = ref(null);
const showSearchResult = ref(false);
const timerToSearch = ref(null);
const responseType = ref(null);
const responseMessage = ref(null);
const loadingSave = ref(false);
const loadingDelete = ref(false);

const getWork = computed(() => store.getters.getWork);
const getForemans = computed(() => store.getters.getForemans);

const init = () => {
  if (props.action === "update") {
    const foremanSelected = props.groupSelected.foreman;
    userKey.value = foremanSelected.key;
    foreman.value = `${foremanSelected.lastName} ${foremanSelected.lastName2} ${foremanSelected.name}`;
    let groupName = props.groupSelected.name;
    let groupNameIndex = groupName.indexOf(': "');
    if (groupNameIndex > -1)
      groupName = groupName.substring(groupNameIndex + 3, groupName.length - 1);
    name.value = groupName;
  }
};

init();

const onSearchForeman = async () => {
  clearTimeout(timerToSearch.value);

  timerToSearch.value = setTimeout(async () => {
    await store.dispatch("findForemans", { q: foreman.value });
    showSearchResult.value = true;
  }, 200);
};

const onSelectForeman = (key, fullName) => {
  userKey.value = key;
  foreman.value = fullName;
  showSearchResult.value = false;
};

const onSave = async (values, { resetForm }) => {
  try {
    loadingSave.value = true;
    responseType.value = null;
    responseMessage.value = null;

    if (props.action === "create")
      await store.dispatch("createGroup", {
        group: { ...values, workKey: getWork.value.key },
      });
    else
      await store.dispatch("updateGroup", {
        group: {
          ...values,
          key: props.groupSelected.key,
          workKey: getWork.value.key,
        },
      });

    resetForm();
    emits("onComplete");
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message || "Ocurrió un error al guardar la información";

    console.error(error);
  } finally {
    loadingSave.value = false;
  }
};

const onDelete = async () => {
  try {
    loadingDelete.value = true;
    responseType.value = null;
    responseMessage.value = null;

    await store.dispatch("deleteGroup", {
      key: props.groupSelected.key,
    });

    emits("onComplete");
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message || "Ocurrió un error al eliminar el grupo";

    console.error(error);
  } finally {
    loadingDelete.value = false;
  }
};
</script>
